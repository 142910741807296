@font-face {
  font-family: NanumSquare;
  src: url("./font/NanumSquareLight.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: NanumSquare;
  src: url("./font/NanumSquareRegular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: NanumSquare;
  src: url("./font/NanumSquareBold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: NanumSquare;
  src: url("./font/NanumSquareExtraBold.ttf") format("truetype");
  font-weight: 800;
}
